@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";


.bg-top-page {
    background-image: linear-gradient(to bottom right, #22bdb7, #44e794);
}

.box-atuacao {
    .item {
        padding: 10px 20px 20px;
        margin: 10px 0;
        border-bottom: 1px solid #e9ecef;
        &:last-child {
            border: 0;
        }
    }
}

.page-item.active {
    a {
        color: #fff;
    }
}

label, .form-label {
    color: #677489;
}

.pointer {
    cursor: pointer;
}

.swal2-container {
    z-index: 9999 !important;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    color: #fff !important;
}

.card {
    &.disabled {
        opacity: 0.7;
    }
}

.multiselect-dropdown .dropdown-btn {
    padding: 0.5rem 0.75rem !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.4rem !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #d2d6da !important;
    border-radius: 0.5rem !important;
    transition: box-shadow 0.15s ease, border-color 0.15s ease !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #7268e5 !important;
    background: #7268e5 !important;
    padding: 0 8px !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    margin: 4px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    top: 12px !important;
    padding: 6px 8px !important;
    height: 26px !important; 
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
    background: #7268e5 !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:before {
    border: 2px solid #7268e5 !important;
    border-radius: 4px !important;
}

.multiselect-item-checkbox input[type=checkbox] + div {
    font-size: 14px;
}

.dropdown-list .filter-textbox input {
    font-size: 14px;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret {
    transform: rotateZ(180deg);
    top: 4px !important;
}

.text-version {
    .swal2-html-container {
            text-align: left !important;
            font-size: 14px !important;
            p {
                font-size: 14px !important;
            }
        }
        .swal2-title {
            font-size: 20px !important;
        }
}


.box-autocomplete {
    position: relative;
    .items {
        background: #fff;
        position: absolute;
        padding: 20px 0;
        z-index: 9;
        width: 100%;
        border-radius: 10px;
        max-height: 250px;
        overflow-y: auto;
        div {
            font-size: 14px;
            margin-bottom: 4px;
            cursor: pointer;
            padding: 5px 25px;
            &:hover {
                background-color: #e9ecef;
            }
        }
    }
}

.navbar-main {
    padding-top: 20px;
}

.navbar-vertical.navbar-expand-xs {
    z-index: 888 !important;
}

.table {
    .badge {
        border: solid 1px;
    }
}

.destaque-status {
    .card {
        border: solid 3px transparent;
        &.empty {
            background: rgba(0, 0, 0, 0.12);
            min-height: 78px;
            .card-body {
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    margin: 0;
                    color: #fff;
                    font-size: 12px;
                }
            }
        }
        .icon-shape {
            width: 52px;
            height: 52px;
            background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1), transparent) !important;
        }
        &.actived {
            border: solid 3px orange;
        }
        .btn {
            position: absolute;
            right: 4px;
            top: 0;
            padding: 2px;
            box-shadow: none;
            font-size: 10px;
            color: #ddd;
            &:hover {
                color: #777;
            }
        }
    }
}


.kaban-container {
    .row {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        padding: 0 0 30px;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .card {
        cursor: ew-resize;
    }
    .item {
        border-radius: 5px 1rem 1rem 5px;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        border: 0;
        border-left-style: solid !important;
        border-width: 4px !important;
        border-color: #5e72e4;
        h6 {
            // font-size: 12px !important;
            text-wrap: wrap;
        }
        &:hover {
            cursor: move;
        }
    }

    .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                    0 8px 10px 1px rgba(0, 0, 0, 0.14),
                    0 3px 14px 2px rgba(0, 0, 0, 0.12);
      }

      .cdk-drag-placeholder {
        opacity: 0;
      }
      
      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      .item-drop {
        min-height: 60px;
      }
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
    height: auto !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 41px;
}

.ng-select .ng-select-container {
    border-radius: 0.5rem;
}

.sidenav-header {
    height: 6rem;
}

.navbar-vertical .navbar-brand>img, .navbar-vertical .navbar-brand-img {
    max-width: 100%;
    max-height: 52px;
}

.kaban-title{
    font-size: 14px!important;
}

.input-category-value{
    padding: 0.35rem 0.75rem;
    border-color: #fff;
}

.input-active{
    border-color: #d2d6da;
}

.input-category-value:disabled {
    background-color: #fff;
}

.blockbuilder-branding {
    display: none !important;
}

.bg-gradient-primary {
    background-image: linear-gradient(310deg, #15BDE5 0%, #72DD1D 100%) !important;
}

.cal-month-view .cal-day-badge {
    background-color: #55cc8c;
    color: #fff;
}

.cal-month-view .cal-event-title {
    cursor: pointer;
    font-size: 14px;
}

.cal-month-view .cal-day-number {
    font-size: 16px;
    font-weight: bold;
    opacity: 0.5;
    margin-top: 15px;
    margin-right: 15px;
    float: right;
    margin-bottom: 10px;
}

.cal-week-view {
    .cal-time-events {
        display: none;
    }
}

.qrcode {
    img {
        max-height: 300px;
        max-width: 100%;
    }
}

.box-qr {
    .fa-qrcode {
        font-size: 260px;
        color: #e9ecef;
    }
}

.bg-gray-100 {
    background-color: #f8f9fa !important;
    min-height: 100vh;
}

span.cal-event-title.ng-star-inserted {
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 0px 2px #040404b0;
}

.cal-week-view .cal-all-day-events .cal-event {
    padding: 0 20px;
    margin-left: 2px;
    margin-right: 2px;
    height: 28px;
    line-height: 28px;
}

ngb-modal-window.d-block.modal.fade.show {
    z-index: 9999;
}

app-demands-form {
    position: relative;
}

.kaban-container .row {
    min-height: 80vh;
}

.nav.nav-tabs {
    margin-bottom: 15px;
    a {
        font-weight: bold;
        font-size: 14px;
    }
}

#solicitation {
    min-height: 100vh;

    .title {
        font-size: 40px;
    }

    * {
        font-family: 'Lato',sans-serif
    }

    i {
        font-family: "Font Awesome 6 Free",sans-serif!important;
    }

    & {
        background: url('assets/solicitations/bg-solicitation.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }

    form{
        h2 {
            color: #092c5a;
            font-weight: 900;
        }

        p.observation {
            color: #85c750;
        }

        label.form-label {
            color: #092c5a;
            font-weight: 900;
        }

        hr{
            border-top: 1px solid;
        }

        .form-check:not(.form-switch) .form-check-input[type="radio"]{
            padding: 10px;
            cursor: pointer;
        }

        .form-check-input[type="radio"]:after {
            z-index: 100;
            content: "";
            position: absolute;
            background-color: transparent!important;
            width: 100% !important;
            height: 100% !important;
            border: 1px solid white;
            top: 0;
            left: 0;
            border-radius: 50%;
            opacity: 1!important;
        }

        .form-check:not(.form-switch) .form-check-input[type="radio"]:checked:after{
            opacity: 0;
        }

        .form-check-input:checked[type="radio"]{
            background: #85c750;
            border: 1px solid #707070;
            outline: 1px solid #fff;
        }
    }

    .insights{
        .card{
            cursor: pointer;

            &.selected{
                .card-header{
                    background: #85C750;
                }
                .card-body{
                    background: #85c7502e;
                }
            }

            textarea{
                border: none;
                background: none;
                box-shadow: none!important;
                height: 100%;
                outline: none;
                resize: none;
            }
        }
        .card-header{
            background: #484848;
        }
        .card-body{
            background: #f3f3f3;
            color: #828282;
            border-radius: 0px 0px 10px 10px;
        }
    }

    .btn-green {
        position: relative;
        box-shadow: none!important;
        background: #85C750 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 12px 35px;
        border: 0;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        img {
            position: absolute;
            top: -7px;
            right: 50%;
            transform: translate(50%, -50%);
        }
    }

    .btn-blue {
        position: relative;
        box-shadow: none!important;
        background: #1752A2 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 12px 35px;
        border: 0;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        img {
            position: absolute;
            top: -7px;
            right: 50%;
            transform: translate(50%, -50%);      
        }
    }

    .btn-gray {
        position: relative;
        box-shadow: none!important;
        background: #828282;
        border-radius: 10px;
        border: 0;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        img {
            position: absolute;
            top: -7px;
            right: 50%;
            transform: translate(50%, -50%);      
        }
    }

    .text-darkblue {
        color: #092c5a;
    }

    .ai-container {
        z-index: 0;
        position: relative;
        .ai-header{
            background-image: url('assets/solicitations/bg-solicitation-ia.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }
        .ai-card{
            box-shadow: 0px 3px 6px #00000029;
        }
    }

    .ck.ck-editor__main>.ck-editor__editable{
        border: none!important;
        background: transparent!important;
        cursor: auto;
        box-shadow: none;
    }

    .ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all{
        display: none
    }

}

[class^="ngx-file-drop__content"]{
    height: 100%!important;
    flex-direction: column;
}

[class^="ngx-file-drop__drop-zone"]{
    height: 151px!important;
    margin: auto!important;
    border: 1px solid lightgray!important;
    border-radius: 10px!important;

    * {
        color: #D2D2D2!important
    }
}

.disabled .dropdown-btn {
    filter: contrast(0.8)
}

.btn-dashboard-collapse {
    min-width: 200px;
    text-align: left;
    border-radius: 50px;
    padding: 8px;
}